import * as constants from '@/store/constants';
import {
  Comptroller,
} from '@/middleware';

// Safety margin variable is used to prevent users to get into liquidation by a fixed percentage.
// i.e. a safety margin of 4% reduces the total borrowing capacity by 4%. If the maximum borrowing
// capacity is 1000, with the safetyMargin the shown borrowing capacity would be 1000/1.04 = 961.54.
// It can be updated as a function if needed
const safetyMargin = 1.04;

const state = {
  comptroller: undefined,
  collateral: undefined,
  liquidity: 0,
  totalBorrows: 0,
  totalDeposits: 0,
  borrowMaxCapacity: 0,
  totalBorrowsAsUnderlying: 0,
  comptrollerLoading: false,
  safetyMargin,
};

const actions = {

  [constants.COMPTROLLER_SET_COMPTROLLER]: async ({ commit }, chainId) => {
    const comptroller = new Comptroller(chainId);
    commit(constants.COMPTROLLER_SET_PROPERTY, { comptroller });
    return comptroller;
  },

  [constants.COMPTROLLER_GET_LIQUIDITY]: ({ commit, rootState }) => {
    const { Session: { walletAddress } } = rootState;
    if (walletAddress) {
      state.comptroller.getAccountLiquidity(walletAddress)
        .then(Number)
        .then((liquidity) => {
          commit(constants.COMPTROLLER_SET_PROPERTY, { liquidity });
        });
    }
  },

  [constants.COMPTROLLER_GET_TOTAL_BORROWS]: ({ commit, rootState }, marketAddress = '') => {
    const { Session: { walletAddress, chainId }, Market: { tokens } } = rootState;
    state.comptroller.totalBorrowsByInterestInUSD(tokens, walletAddress, chainId, marketAddress)
      .then(({ totalBorrowsByInterest, totalBorrowsAsUnderlying }) => (
        [Number(totalBorrowsByInterest), Number(totalBorrowsAsUnderlying)]
      ))
      .then(([totalBorrows, totalBorrowsAsUnderlying]) => {
        commit(constants.COMPTROLLER_SET_PROPERTY, { totalBorrows });
        commit(constants.COMPTROLLER_SET_PROPERTY, { totalBorrowsAsUnderlying });
      });
  },
  [constants.COMPTROLLER_GET_BORROW_CAP]: async ({ rootState }, marketAddress = '') => {
    const { Session: { walletAddress, chainId }, Market: { tokens } } = rootState;
    const response = await state.comptroller.getBorrowCap(marketAddress);
    return response;
  },

  [constants.COMPTROLLER_GET_TOTAL_DEPOSITS]: ({ commit, rootState }) => {
    const { Session: { walletAddress, chainId }, Market: { tokens } } = rootState;
    if (walletAddress) {
      state.comptroller.totalDepositsByInterestInUSD(tokens, walletAddress, chainId)
        .then(({ totalDeposits }) => (
          [Number(totalDeposits)]
        ))
        .then(([totalDeposits]) => {
          commit(constants.COMPTROLLER_SET_PROPERTY, { totalDeposits });
        });
    }
  },

  [constants.COMPTROLLER_BORROW_MAX_CAPACITY]: ({ commit, rootState }) => {
    const { Session: { walletAddress, chainId }, Market: { tokens } } = rootState;
    if (walletAddress) {
      commit(constants.COMPTROLLER_SET_PROPERTY, {
        comptrollerLoading: true,
      });
      state.comptroller.borrowMaxCapacity(tokens, walletAddress, chainId)
        .then((borrowMaxCapacity) => Number(borrowMaxCapacity))
        .then((borrowMaxCapacity) => {
          const value = borrowMaxCapacity / safetyMargin;
          commit(constants.COMPTROLLER_SET_PROPERTY, {
            borrowMaxCapacity: value,
          });
          commit(constants.COMPTROLLER_SET_PROPERTY, {
            collateral: borrowMaxCapacity,
          });
          commit(constants.COMPTROLLER_SET_PROPERTY, {
            comptrollerLoading: false,
          });
        });
    }
  },

  // eslint-disable-next-line no-shadow
  [constants.COMPTROLLER_ENTER_MARKET_SIMULATOR]: ({ commit, rootState }) => {
    const { Market: { markets } } = rootState;
    let borrowMaxCapacity = 0;
    markets.forEach((market) => {
      borrowMaxCapacity += market.supplyBalance * market.collateralFactor * market.price;
    });
    const value = borrowMaxCapacity / safetyMargin;
    borrowMaxCapacity = borrowMaxCapacity > 0 ? borrowMaxCapacity : 0;
    // When no borrow has been requested, liquidity is equal to borrow capacity.
    commit(constants.COMPTROLLER_SET_PROPERTY, { borrowMaxCapacity: value });
    commit(constants.COMPTROLLER_SET_PROPERTY, { collateral: borrowMaxCapacity });
    commit(constants.COMPTROLLER_SET_PROPERTY, { liquidity: borrowMaxCapacity });
  },

};

const mutations = {

  // eslint-disable-next-line no-shadow
  [constants.COMPTROLLER_SET_PROPERTY]: (state, data) => {
    const [[property, value]] = Object.entries(data);
    state[property] = value;
  },

};

export default {
  state,
  actions,
  mutations,
};
