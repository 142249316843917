<template>
  <v-app class="app secondary-color hey" :class="`${nameRouter}`">
    <div class="d-flex">
      <left-bar />
      <div class="container-app secondary-color">
        <navbar />
        <router-view />
      </div>
    </div>
    <notifications-content />
    <feedback-bubble />

    <template v-if="dialogTerms">
      <terms-conditions :showModal="dialogTerms" @closed="closeTerms" />
    </template>
    <template v-if="showAppInstallDialog">
      <install-app
        :showModal="showAppInstallDialog"
        @closed="setAppInstallDialog(false)"
        @installApp="setAppInstallDialog(false)"
      />
    </template>
  </v-app>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import FeedbackBubble from '@/components/menu/FeedbackBubble.vue';
import Navbar from '@/components/menu/Navbar.vue';
import LeftBar from '@/components/menu/LeftBar.vue';
import NotificationsContent from '@/components/general/NotificationsContent.vue';
import TermsConditions from '@/components/dialog/TermsAndConditions.vue';
import InstallApp from '@/components/dialog/InstallPrompt.vue';
import * as constants from '@/store/constants';
import { getUserActivity, getUserId, setTermsAndConditions } from '@/services/users';
import { addresses } from '@/middleware/contracts/constants';
import { CRbtc } from '@/middleware';
import { OpenloginAdapter } from '@web3auth/openlogin-adapter';
import { ADAPTER_EVENTS, CHAIN_NAMESPACES } from '@web3auth/base';
import QRCodeModal from '@walletconnect/qrcode-modal';
import { Web3Auth } from '@web3auth/modal';
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider';
import { WalletConnectV2Adapter, getWalletConnectV2Settings } from '@web3auth/wallet-connect-v2-adapter';
import { WC_PROJECT_ID } from '@/config/network.config';
import { setTermsAndConditionsMP, EVENT_NAME } from '@/utils/mixpanel';

export default {
  name: 'App',
  components: {
    FeedbackBubble,
    Navbar,
    LeftBar,
    NotificationsContent,
    TermsConditions,
    InstallApp,
  },
  data() {
    return {
      nameRouter: '',
      drawer: false,
      btcToRbtcDialog: true,
      marketAddresses: [],
      markets: [],
      dontShowWelcomeModal: false,
      isPioneer: false,
      authorized: false,
      activePioneer: true,
      dialogTerms: false,
      dialogKripton: false,
      deferredPrompt: false,
    };
  },
  computed: {
    ...mapState({
      chainId: (state) => state.Session.chainId,
      walletAddress: (state) => state.Session.walletAddress,
      typeConnection: (state) => state.Session.typeConnection,
      comptroller: (state) => state.Comptroller.comptroller,
      environment: (state) => state.Session.environment,
      userId: (state) => state.Session.userId,
      markets1: (state) => state.Market.markets,
      loadingMarket: (state) => state.Market.loadingMarket,
      account: (state) => state.Session.account,
      lastSession: (state) => state.Session.lastSession,
      showDialogConnect: (state) => state.Session.showDialogConnect,
      showAppInstallDialog: (state) => state.Session.showAppInstallDialog,
      wallet: (state) => state.Session.wallet,
      isMobile: (state) => state.Session.isMobile,
      provider: (state) => state.Session.provider,
    }),
  },
  methods: {
    getRoute() {
      this.nameRouter = this.$route.name;
      const langs = ['es', 'en', 'pt'];
      const { lang } = this.$route.query;
      if (langs.indexOf(lang) !== -1) {
        this.$i18n.locale = lang;
      }
    },
    ...mapActions({
      addMarkets: constants.SESSION_ADD_MARKETS,
      setComptroller: constants.COMPTROLLER_SET_COMPTROLLER,
      disconnectWallet: constants.SESSION_DISCONNECT_WALLET,
      loadMarkets: constants.MARKET_LOAD_MARKETS,
      setAppInstallDialog: constants.SESSION_SET_APP_INSTALL_DIALOG,
      getGasPrice: constants.MARKET_GET_CURRENT_GAS_PRICE,
    }),
    ...mapMutations({
      setWeb3AuthInstance: constants.SESSION_SET_W3A_INSTANCE,
      setSessionProperty: constants.SESSION_SET_PROPERTY,

    }),
    async connectToLastSession() {
      const lastSessionMethod = window.localStorage.getItem('tpks-last-session-method');
      const lastSessionWallet = window.localStorage.getItem('tpks-last-session-wallet');
      if (lastSessionMethod && lastSessionWallet) {
        await this.$store.dispatch(lastSessionMethod, { wallet: lastSessionWallet });
      }
    },
    closeDialog() {
      this.isPioneer = false;
      setTimeout(() => {
        this.termsAndConditions();
      }, 1000);
    },
    async closeTerms(data) {
      try {
        if (data) {
          setTermsAndConditionsMP(EVENT_NAME.TACI, this.wallet);
          const signer = this.provider.getSigner();
          const message = 'Aceptar terminos y condiciones para el uso del protocolo Tropykus Finance';
          const signedMessage = await signer.signMessage(message);
          const acceptTACObj = {
            address: this.walletAddress,
            message,
            signed_message: signedMessage,
            wallet_name: this.wallet,
          };
          const response = await setTermsAndConditions(acceptTACObj);
          this.dialogTerms = !response;
          setTermsAndConditionsMP(EVENT_NAME.TACC, this.wallet);
        } else {
          this.dialogTerms = false;
          setTermsAndConditionsMP(EVENT_NAME.TACR, this.wallet);
          this.disconnectWallet();
        }
      } catch (error) {
        console.error('error: ', error);
        this.dialogTerms = false;
        setTermsAndConditionsMP(EVENT_NAME.TACE, this.wallet, error);
        this.disconnectWallet();
      }
    },
    async termsAndConditions() {
      try {
        if (this.walletAddress) {
          const { findFirstUsers } = await getUserId(this.walletAddress);
          this.dialogTerms = !findFirstUsers?.tac_response;
        } else {
          this.dialogTerms = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    deleteWalletConnectConnection() {
      const conection = localStorage.getItem('walletconnect');
      localStorage.removeItem('openlogin_store');
      localStorage.removeItem('Web3Auth-cachedAdapter');
      if (conection) {
        localStorage.removeItem('walletconnect');
      }
    },
    async getActivityUser() {
      if (!this.userId) return;
      const { activity } = await getUserActivity(this.userId, 1, 0);
      if (activity.length > 0) {
        if (this.$route.name === constants.ROUTE_NAMES.BALANCE
        || this.$route.name === constants.ROUTE_NAMES.HOMEDASHBOARD) return;
        this.$router.push({ name: constants.ROUTES.HOME.DASHBOARD });
      }
    },
    async hasRbtc() {
      const rbtcMarket = addresses[this.chainId].kRBTC;
      const market = new CRbtc(rbtcMarket, this.chainId);
      const amount = await market.balanceOfUnderlyingInWallet(this.account);
      if (Number(amount) === 0) this.dialogKripton = true;
    },
    async initWeb3Auth() {
      try {
        const clientId = process.env.VUE_APP_CLIENT_ID;
        const blockExplorer = this.chainId === 31 ? 'https://explorer.testnet.rsk.co/' : 'https://explorer.rsk.co/';
        const ticker = this.chainId === 31 ? 'tRBTC' : 'RBTC';
        const tickerName = this.chainId === 31 ? 'rBTC Testnet' : 'rBTC Mainnet';
        const rpcTarget = this.chainId === 31
          ? process.env.VUE_APP_RSK_NODE_TESTNET : process.env.VUE_APP_RSK_NODE;
        const displayName = this.chainId === 31 ? 'RSK Testnet' : 'RSK Mainnet';
        const chainIdValue = this.chainId === 31 ? '0x1f' : '0x1E';

        const chainConfig = {
          chainNamespace: CHAIN_NAMESPACES.EIP155,
          chainId: chainIdValue,
          rpcTarget,
          displayName,
          blockExplorer,
          ticker,
          tickerName,
        };

        const web3AuthInstance = new Web3Auth({
          chainConfig,
          clientId,
          enableLogging: false,
        });

        const privateKeyProvider = new EthereumPrivateKeyProvider({
          config: { chainConfig },
        });
        const openloginAdapter = new OpenloginAdapter({
          adapterSettings: {
            whiteLabel: {
              name: 'W3A Heroes',
              url: 'https://web3auth.io',
              logoLight: 'https://web3auth.io/images/w3a-L-Favicon-1.svg',
              logoDark: 'https://web3auth.io/images/w3a-D-Favicon-1.svg',
              defaultLanguage: 'es', // en, de, ja, ko, zh, es, fr, pt, nl
              dark: false, // whether to enable dark mode. defaultValue: false
              theme: {
                primary: '#00D1B2',
              },
            },
            mfaSettings: {
              deviceShareFactor: {
                enable: true,
                priority: 1,
                mandatory: true,
              },
              backUpShareFactor: {
                enable: true,
                priority: 2,
                mandatory: false,
              },
              socialBackupFactor: {
                enable: true,
                priority: 3,
                mandatory: false,
              },
              passwordFactor: {
                enable: true,
                priority: 4,
                mandatory: false,
              },
            },
          },
          loginSettings: {
            mfaLevel: 'mandatory',
          },
          privateKeyProvider,
        });

        // adding wallet connect v2 adapter
        const defaultWcSettings = await getWalletConnectV2Settings(
          'eip155',
          [30, 31],
          WC_PROJECT_ID,
        );
        const walletConnectV2Adapter = new WalletConnectV2Adapter({
          adapterSettings: {
            qrcodeModal: QRCodeModal,
            ...defaultWcSettings.adapterSettings,
          },
          loginSettings: { ...defaultWcSettings.loginSettings },
        });

        web3AuthInstance.configureAdapter(walletConnectV2Adapter);
        web3AuthInstance.configureAdapter(openloginAdapter);

        const subscribeAuthEvents = (web3) => {
          web3.on(ADAPTER_EVENTS.CONNECTED, async (data) => {
            const privateKey = await web3.provider.request({
              method: 'eth_private_key',

            });
            this.setSessionProperty({ w3Authpk: privateKey });
            // web3.provider will be available here after user is connected
          });
          web3.on(ADAPTER_EVENTS.CONNECTING, () => {
          });
          web3.on(ADAPTER_EVENTS.DISCONNECTED, () => {
          });
          web3.on(ADAPTER_EVENTS.ERRORED, (error) => {
          });
          web3.on(ADAPTER_EVENTS.READY, (data) => {
          });
        };

        subscribeAuthEvents(web3AuthInstance);
        await web3AuthInstance.init();
        this.setWeb3AuthInstance(web3AuthInstance);
      } catch (error) {
        console.error('error: ', error);
      }
    },
    showAppInstallPrompt() {
      const isBeexoBrowser = this.wallet === 'WALLET_BEEXO' && this.isMobile;
      const isMTMBrowser = this.wallet === 'WALLET_METAMASK' && this.isMobile;
      const dontRemind = localStorage.getItem('trpksDontShowAppInstallDialog');
      if (this.$beforeInstallPromptEvent && !this.showDialogConnect && !dontRemind
      && !isBeexoBrowser && !isMTMBrowser) {
        this.setAppInstallDialog(true);
      } else {
        this.setAppInstallDialog(false);
      }
    },
    redirect(routePath, section) {
      if (routePath === constants.ROUTES.HOME.DASHBOARD) return;
      const to = { name: routePath, params: { id: section } };
      this.$router.push(to);
    },

  },
  watch: {
    userId() {
      if (!this.userId) return;
      this.getActivityUser();
    },
    $route() {
      this.getRoute();
    },
    chainId(val) {
      this.setComptroller(this.chainId);
      this.loadMarkets();
      if (val) {
        this.$forceUpdate();
      }
    },
    dontShowWelcomeModal() {
      localStorage.flag = !this.dontShowWelcomeModal;
    },
    walletAddress() {
      if (!this.walletAddress) return;
      this.hasRbtc();
      setTimeout(() => {
        this.termsAndConditions();
        this.showAppInstallPrompt();
      }, 3000);
      this.redirect(constants.ROUTES.HOME.DASHBOARD);
    },
  },
  created() {
    this.loadMarkets();
    this.setComptroller(this.chainId);
    this.deleteWalletConnectConnection();
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      // TODO: Only Reload if wallet not connected
      if (!this.walletAddress) {
        window.location.reload();
      }
    });
    window.onbeforeunload = () => {
      localStorage.removeItem('RLOGIN_CACHED_PROVIDER');
      localStorage.removeItem('RLOGIN_SELECTED_PROVIDER');
      localStorage.removeItem('walletconnect');
    };
  },
  mounted() {
    this.getRoute();
    if (localStorage.flag) {
      this.btcToRbtcDialog = localStorage.flag === 'true';
    }
    this.connectToLastSession();
    this.initWeb3Auth();
    this.getGasPrice();
  },
};
</script>
