<template>
  <div>
    <template v-if="isLoading">
      <loading :showModal="isLoading" :data="dataLoading" @closed="closeNotification" />
    </template>
    <div class="content-notifications">
      <template v-if="showNotification">
        <notification :data="notifications" />
      </template>
    </div>

    <template v-if="showModalConnectWallet">
      <connect-wallet :showModal="showModalConnectWallet" @closed="closeDialog" />
    </template>
    <template v-if="showDialogQrAddress">
      <qr-address-dialog :showModal="showDialogQrAddress" @closed="closeDialog" />
    </template>

    <w3a-guide />
    <w3a-pk />

  </div>
</template>
<script>
import Loading from '@/components/modals/Loading.vue';
import Notification from '@/components/general/Notifications.vue';
import ConnectWallet from '@/components/dialog/ConnectWallet.vue';
import QrAddressDialog from '@/components/dialog/QrAddressDialog.vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import * as constants from '@/store/constants';
import W3aGuide from '../dialog/W3aGuide.vue';
import W3aPk from '@/components/dialog/W3aPk.vue';

export default {
  components: {
    Loading,
    Notification,
    ConnectWallet,
    QrAddressDialog,
    W3aGuide,
    W3aPk,
  },
  data() {
    return {
      isLoading: false,
      showModalConnectWallet: false,
      dataLoading: {},
      dbName: process.env.VUE_APP_DB_SAVING_THE_AMAZON,
      borrowAction: false,
    };
  },
  watch: {
    dialogLoading() {
      this.isLoading = this.dialogLoading;
    },
    infoLoading() {
      this.dataLoading = this.infoLoading;
    },
    showDialogConnect() {
      this.showModalConnectWallet = this.showDialogConnect;
    },
    walletAddress() {
      if (this.walletAddress && this.routeBalance) {
        const to = { name: constants.ROUTE_NAMES.BALANCE };
        this.$router.push(to);
      }
      if (this.walletAddress && this.routeDeposits) {
        const to = { name: constants.ROUTE_NAMES.DEPOSITS };
        this.$router.push(to);
      }
      if (this.walletAddress && this.routeBorrows) {
        const to = { name: constants.ROUTE_NAMES.BORROWS };
        this.$router.push(to);
      }
    },
  },
  computed: {
    ...mapState({
      infoLoading: (state) => state.Users.infoLoading,
      dialogLoading: (state) => state.Users.dialogLoading,
      notifications: (state) => state.Users.notifications,
      showDialogConnect: (state) => state.Session.showDialogConnect,
      showDialogQrAddress: (state) => state.Session.showDialogQrAddress,
      routeBalance: (state) => state.Session.routeBalance,
      routeDeposits: (state) => state.Session.routeDeposits,
      routeBorrows: (state) => state.Session.routeBorrows,
      walletAddress: (state) => state.Session.walletAddress,
      depositUpdate: (state) => state.Users.depositUpdate,
      chainId: (state) => state.Session.chainId,
    }),
    showNotification() {
      return this.notifications && !this.dialogLoading;
    },
  },
  methods: {
    ...mapActions({
      showDialogWallet: constants.SESSION_SHOW_DIALOG_CONNECT,
      setDialogQrAddress: constants.SESSION_SHOW_DIALOG_QR_ADDRESS,
      setDrawer: constants.SESSION_DRAWER,
      connectToWeb3: constants.SESSION_CONNECT_WEB3,
    }),
    ...mapMutations({
      setDialogLoading: constants.USER_SET_PROPERTY,
    }),

    getCodeSnapshot(code) {
      this.$db
        .collection(this.dbName)
        .doc(code)
        .onSnapshot((doc) => {
          const { address } = doc.data();
        });
    },
    closeNotification() {
      this.setDialogLoading({ dialogLoading: false });
      this.borrowAction = false;
    },

    closeDialog(value) {
      this.setDialogQrAddress(false);
      this.showDialogWallet(false);
    },
  }
};
</script>
