<template>
  <div>
    <v-dialog v-model="dialog" persistent content-class="d-terms-conditions-pk"
      overlay-opacity="0.6" overlay-color="#000">
      <v-card
        class="dialog-terms-conditions-pk ma-0 secondary-color d-flex align-center justify-center">
        <v-icon class="btn-close-dialog mt-2" @click="justClose()">mdi-close</v-icon>
        <div class="content-terms p1-descriptions mt-3">
          <div class="h3-sections-heading text-info">
            {{$t('dialog.w3a-export-pk.title')}}
          </div>
          <div class="box-terms d-flex justify-start align-center mt-4">
            <img src="@/assets/dialog/info.svg" class="mr-3" alt="info-icon"/>
            {{$t('dialog.w3a-export-pk.description')}}
          </div>
          <div   class="d-flex align-center justify-center mt-2">
            <div  class="d-flex align-center justify-center" v-if="!copied">
              <v-checkbox
                v-model="agree"
                color="#042F24"
                class=""
                hide-details
              ></v-checkbox>
              <div class="mt-5 agree">{{$t('dialog.w3a-export-pk.agree')}}
              </div>
            </div>
            <p  class="mt-5" v-else>{{$t('dialog.w3a-export-pk.copied')}}</p>
          </div>
          <v-btn text block class="btn mt-5"
            :disabled="!activeButton"
            @click="closeDialog"
            :class="activeButton ? 'primary-color' : 'btn-disabled'"
          >
            <span v-if="!copied" class="white--text">
              {{$t('dialog.w3a-export-pk.cta')}}
            </span>
            <span v-else class="white--text">
              {{$t('dialog.w3a-export-pk.close')}}
            </span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import * as constants from '@/store/constants';
import copy from 'copy-to-clipboard';

export default {
  name: 'Risk',
  data() {
    return {
      dialog: false,
      agree: false,
      copied: false,
    };
  },
  watch: {
    showPkAlert() {
      this.dialog = this.showPkAlert;
    },
  },
  computed: {
    activeButton() {
      return this.agree === true;
    },
    ...mapState({
      showPkAlert: (state) => state.Session.showPkAlert,
      w3AuthPk: (state) => state.Session.w3Authpk,
    }),
  },
  methods: {
    ...mapActions({
      showDialog: constants.SESSION_SHOW_PK_ALERT,
    }),
    copyToClipboard() {
      copy(this.w3AuthPk);
      this.copied = true;
    },
    closeDialog() {
      if (this.copied) this.justClose();
      else this.copyToClipboard();
    },
    justClose() {
      this.showDialog(false);
      this.copied = false;
      this.agree = false;
    }
  },
};
</script>
