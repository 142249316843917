/* eslint-disable no-multi-assign */
import * as constants from '@/store/constants';
import Vue from 'vue';
import { ethers } from 'ethers';
import Tropykus from '@tropykus-finance/tropykus';
import { addresses } from '@/middleware/contracts/constants';
import rLogin from '@/config/rLogin.config';
import { rpcUrls, webSocketUrls, supportedChains } from '@/config/network.config';
/* eslint-disable */
import axios from 'axios';
import { getUserId } from '@/services/users';
import detectEthereumProvider from "@metamask/detect-provider";
import { EVENT_NAME, setWalletConnectionEventMP } from '../../utils/mixpanel';

const state = {
  chainId: Number(process.env.VUE_APP_DEFAULT_CHAIN_ID),
  supportedChains,
  environment: process.env.VUE_APP_ENVIRONMENT,
  provider: undefined,
  walletAddress: undefined,
  account: undefined,
  wallet: undefined,
  disconnect: undefined,
  authorizationCheck: true,
  markets: [],
  drawer: false,
  showAppInstallDialog: false,
  showDialogConnect: false,
  showDialogQrAddress: false,
  newContentAvailable: false,
  routeBalance: false,
  routeDeposits: false,
  routeBorrows: false,
  comptroller: null,
  tropykus: {},
  failRed: false,
  userId: null,
  web3AuthInstance: undefined,
  isMobile:false,
  showData: [],
  notificationsEmail: undefined,
  w3Authpk: undefined,
  showPkAlert: false,

};

const actions = {

  [constants.SESSION_CONNECT_RLOGIN]: async ({ commit, dispatch, state }) => {
    const showData = state.showData;
    commit(constants.SESSION_SET_PROPERTY, { showData: {...showData, message: 'start' }})
    setWalletConnectionEventMP(EVENT_NAME.WCS, 'rlogin');
    try {
      commit(constants.SESSION_SET_PROPERTY, { showDialogConnect: false });
      commit(constants.SESSION_SET_PROPERTY, { showData: {...showData, message: 'init' }});
      const { provider, disconnect } = await rLogin.connect();
      const chainId = Number(provider.chainId);
      commit(constants.SESSION_SET_PROPERTY, { showData: {...showData, ...{ provider } }});

      const web3 = new ethers.providers.Web3Provider(provider);
      Vue.prototype.$web3 = Vue.web3 = web3;
      
      const web3Ws = new ethers.providers.WebSocketProvider(webSocketUrls[chainId]);
      Vue.prototype.$web3Ws = Vue.web3Ws = web3Ws;
      
      const account = web3.getSigner(0);
      const walletAddress = await account.getAddress();
      
      Vue.prototype.$tropykus = Vue.tropykus = new Tropykus(web3, web3Ws, 900000);
      Vue.tropykus.setPriceOracle(addresses[chainId].priceOracleProxy);
      
      const tropykusAccount = await Vue.tropykus.getAccount();
      const tropykusComptroller = await Vue.tropykus.setComptroller(
        tropykusAccount,
        addresses[chainId].comptroller
      );
      const tropykusMkts = await tropykusComptroller.getAllMarketsInstances(
        addresses[chainId].kSAT,
        addresses[chainId].kRBTC,
        addresses[chainId].krDOC
      );

      const tropykus = {
        account: tropykusAccount,
        comptroller: tropykusComptroller,
        mkts: tropykusMkts,
      };
      let wallet_name = ''
      const trezor = provider?.isTrezor;
      const ledger = provider?.isLedger;
      const exodus = provider?.isExodus;
      const rabby = provider?.isRabby;
      if (trezor) {
        wallet_name = 'Trezor';
      } else if (ledger) {
        wallet_name = 'Ledger';
      } 
      else if (exodus) {
        wallet_name = constants.WALLET_EXODUS;
      } 
      else if (rabby) {
        wallet_name = constants.WALLET_RABBY;
      } 
      else {
        wallet_name = provider?.wc?._peerMeta?.name;
        const isBeexo = wallet_name === 'Beexo';
        if (isBeexo) commit(constants.SESSION_SET_WALLET, constants.WALLET_BEEXO);
      }
      commit(constants.SESSION_SET_PROPERTY, { chainId });
      commit(constants.SESSION_SET_PROPERTY, { disconnect });
      commit(constants.SESSION_SET_PROPERTY, { provider: web3 });
      commit(constants.SESSION_SET_PROPERTY, { tropykus });
      commit(constants.SESSION_SET_PROPERTY, { account });
      commit(constants.SESSION_SET_PROPERTY, { walletAddress });
      dispatch(constants.SESSION_GET_USER_ID, walletAddress);
      commit(constants.SESSION_SET_PROPERTY, { wallet: wallet_name });
      setWalletConnectionEventMP(EVENT_NAME.RLOGIN, 'rlogin', provider);
    } catch (error) {
      commit(constants.SESSION_SET_PROPERTY, { showData: {...showData, ...{ error } }});
      const dataError = {
        message: error?.message,
        error,
      }
      setWalletConnectionEventMP(EVENT_NAME.WCE, 'rlogin', dataError);
    }
  },
  [constants.SESSION_CONNECT_WEB3]: async ({ commit, dispatch }, data) => {
    setWalletConnectionEventMP(EVENT_NAME.WCS, data.wallet);
    let provider = null;
    let chainId = 30;
    const defaultChainId = process.env.VUE_APP_DEFAULT_CHAIN_ID;
    commit(constants.SESSION_SET_PROPERTY, data);

    const LIQUALITY = data.wallet === constants.WALLET_LIQUALITY && window?.eth?.isLiquality;
    const METAMASK = data.wallet === constants.WALLET_METAMASK && window.ethereum.isMetaMask;

    provider = await detectEthereumProvider();
    chainId = data?.chainId ? data?.chainId : parseInt(provider?.chainId, 16);
    const supportedNetworks = process.env.VUE_APP_SUPPORTED_NETWORKS.split(' ').map(Number);

    const fetchChainInformation = async () => {
      const chains = await axios.get('https://chainid.network/chains.json');
      // eslint-disable-next-line max-len
      const chain = chains.data.find((c) => c.chainId?.toString() === defaultChainId.toString());
      return chain;
    };

    const connectMetamask = async () => {
      // Identify default network (testnet | mainnet)
      const chain = await fetchChainInformation();
      const toHex = (num) => `0x${num.toString(16)}`;
      const chainIdHex = toHex(chain.chainId); // A 0x-prefixed hexadecimal string

      try {
        // Switch to RSK Network if available
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chainIdHex }],
        });
      } catch (error) {
        // If RSK Network is unavailable we'll try to add it
        if (error.code === 4902) {
          try {
            const params = [
              {
                chainId: chainIdHex,
                chainName: chain.name,
                nativeCurrency: {
                  name: chain.nativeCurrency.name,
                  symbol: chain.nativeCurrency.symbol, // 2-6 characters long
                  decimals: chain.nativeCurrency.decimals,
                },
                rpcUrls: chain.rpc,
                blockExplorerUrls: [
                  chain.explorers && chain.explorers.length > 0 && chain.explorers[0].url
                    ? chain.explorers[0].url
                    : chain.infoURL,
                ],
              },
            ];

            // We ask to add RSK network
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params,
            });

            // Then we switch it on the same step
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: chainIdHex }],
            });
          } catch (addError) {
            setWalletConnectionEventMP(EVENT_NAME.WCE, data.wallet, addError);
            throw new Error(addError);
          }
        }
      } finally {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
      }
      return chain.chainId;
    };

    try {
      switch (true) {
        case LIQUALITY:
          provider = window.rsk;
          chainId = parseInt(provider?.chainId, 16);
          await provider.enable();
          await provider.request({ method: 'eth_requestAccounts' });
          break;

        case METAMASK:
          chainId = await connectMetamask();
          break;

        default:
          return;
      }

      // error red
      if (supportedNetworks.indexOf(chainId) !== -1) {
        commit(constants.SESSION_SET_PROPERTY, { failRed: false });
      } else {
        commit(constants.SESSION_SET_PROPERTY, { failRed: true });
        return;
      }

      provider.on('chainChanged', (val) => {
        if (supportedNetworks.indexOf(Number(val)) !== -1) {
          commit(constants.SESSION_SET_PROPERTY, { failRed: false });
        } else {
          commit(constants.SESSION_SET_PROPERTY, { failRed: true });
        }
      });

      Vue.prototype.$web3 = Vue.web3 = new ethers.providers.Web3Provider(provider, 'any');
      Vue.prototype.$web3Ws = Vue.web3Ws = new ethers.providers.WebSocketProvider(
        Number(chainId) === 30
          ? process.env.VUE_APP_WS_PROVIDER
          : process.env.VUE_APP_WS_PROVIDER_TESTNET
      );

      Vue.prototype.$tropykus = Vue.tropykus = new Tropykus(Vue.web3, Vue.web3Ws, 900000);
      Vue.tropykus.setPriceOracle(addresses[chainId].priceOracleProxy);

      const tropykusAccount = await Vue.tropykus.getAccount();
      const tropykusComptroller = await Vue.tropykus.setComptroller(
        tropykusAccount,
        addresses[chainId].comptroller
      );
      const tropykusMkts = await tropykusComptroller.getAllMarketsInstances(
        addresses[chainId].kSAT,
        addresses[chainId].kRBTC,
        addresses[chainId].krDOC,
        addresses[chainId].kBPRO,

      );

      const tropykus = {
        account: tropykusAccount,
        comptroller: tropykusComptroller,
        mkts: tropykusMkts,
      };

      const account = await Vue.web3.getSigner();
      const walletAddress = await account.getAddress();

      commit(constants.SESSION_SET_PROPERTY, { chainId });
      commit(constants.SESSION_SET_PROPERTY, { tropykus });
      commit(constants.SESSION_SET_PROPERTY, { provider: Vue.web3 });
      commit(constants.SESSION_SET_PROPERTY, { account });
      commit(constants.SESSION_SET_PROPERTY, { walletAddress });
      dispatch(constants.SESSION_GET_USER_ID, walletAddress);
      dispatch(constants.SESSION_SHOW_DIALOG_CONNECT, false);
      if (data.wallet !== constants.WALLET_CONNECT) {
        commit(constants.SESSION_SET_PROPERTY, { wallet: data.wallet });
      }
      localStorage.setItem('tpks-last-session-method', constants.SESSION_CONNECT_WEB3);
      localStorage.setItem('tpks-last-session-wallet', data.wallet);
      setWalletConnectionEventMP(EVENT_NAME.WCC, data.wallet);
    } catch (error) {
      const dataError = {
        message: error?.message,
        error,
      }
      setWalletConnectionEventMP(EVENT_NAME.WCE, data.wallet, dataError);
      localStorage.removeItem('tpks-last-session-method');
      localStorage.removeItem('tpks-last-session-wallet');
      // TODO: Catch and deal with possible error/s
      // console.error(`${error.name}: ${error.message}`);
    }
  },

  [constants.SESSION_CONNECT_WEB3AUTH]: async (
    { commit, dispatch, state },
    { walletAdapter, loginProvider, login_hint }
  ) => {
    setWalletConnectionEventMP(EVENT_NAME.WCS, 'WEB3AUTH');
    commit(constants.SESSION_SET_PROPERTY, { showDialogConnect: false });
    const { chainId, web3AuthInstance } = state;

    try {
      const localProvider = await web3AuthInstance.connectTo(walletAdapter, {
        loginProvider,
        login_hint,
      });
      Vue.prototype.$web3 = Vue.web3 = new ethers.providers.Web3Provider(localProvider, 'any');
      Vue.prototype.$web3Ws = Vue.web3Ws = new ethers.providers.WebSocketProvider(
        Number(state.chainId) === 30
          ? process.env.VUE_APP_WS_PROVIDER
          : process.env.VUE_APP_WS_PROVIDER_TESTNET
      );

      Vue.prototype.$tropykus = Vue.tropykus = new Tropykus(Vue.web3, Vue.web3Ws, 900000);
      Vue.tropykus.setPriceOracle(addresses[chainId].priceOracleProxy);

      const tropykusAccount = await Vue.tropykus.getAccount();

      const tropykusComptroller = await Vue.tropykus.setComptroller(
        tropykusAccount,
        addresses[chainId].comptroller
      );
      const tropykusMkts = await tropykusComptroller.getAllMarketsInstances(
        addresses[chainId].kSAT,
        addresses[chainId].kRBTC,
        addresses[chainId].krDOC
      );

      const tropykus = {
        account: tropykusAccount,
        comptroller: tropykusComptroller,
        mkts: tropykusMkts,
      };

      // state.provider = provider;
      const account = await Vue.web3.getSigner();
      const walletAddress = await account.getAddress();

      commit(constants.SESSION_SET_PROPERTY, { chainId });
      commit(constants.SESSION_SET_PROPERTY, { tropykus });
      commit(constants.SESSION_SET_PROPERTY, { provider: Vue.web3 });
      commit(constants.SESSION_SET_PROPERTY, { account });
      commit(constants.SESSION_SET_PROPERTY, { walletAddress });
      commit(constants.SESSION_SET_PROPERTY, { wallet: 'WEB3AUTH' });
      dispatch(constants.SESSION_GET_USER_ID, walletAddress);
      setWalletConnectionEventMP(EVENT_NAME.WCC, 'WEB3AUTH');
      return localProvider;
    } catch (error) {
      const dataError = {
        message: error?.message,
        error,
      }
      setWalletConnectionEventMP(EVENT_NAME.WCE, 'WEB3AUTH', dataError);
      console.error('web3auth.connect', error);
    }
  },

  [constants.SESSION_DISCONNECT_WALLET]: async ({ commit }) => {
    const chainId = Number(process.env.VUE_APP_DEFAULT_CHAIN_ID);

    const web3 = new ethers.providers.JsonRpcProvider(rpcUrls[chainId]);
    const format = web3.formatter.formats;
    format.receipt.root = format.receipt.logsBloom;
    Object.assign(web3.formatter, { format });
    Vue.prototype.$web3 = Vue.web3 = web3;

    if (state.disconnect !== undefined) {
      state.disconnect();
    }
    if (state.wallet === 'WEB3AUTH') state.web3AuthInstance.logout();

    localStorage.removeItem('tpks-last-session-method');
    localStorage.removeItem('tpks-last-session-wallet');
    localStorage.removeItem('RLOGIN_CACHED_PROVIDER');
    localStorage.removeItem('RLOGIN_SELECTED_PROVIDER');
    localStorage.removeItem('walletconnect');
    commit(constants.SESSION_SET_PROPERTY, { chainId });
    commit(constants.SESSION_SET_PROPERTY, { disconnect: undefined });
    commit(constants.SESSION_SET_PROPERTY, { provider: undefined });
    commit(constants.SESSION_SET_PROPERTY, { account: undefined });
    commit(constants.SESSION_SET_PROPERTY, { wallet: undefined });
    commit(constants.SESSION_SET_PROPERTY, { walletAddress: undefined });
    commit(constants.SESSION_SET_PROPERTY, { userId: undefined });
    commit(constants.USER_SET_PROPERTY, { enterMarkets: false });
    commit(constants.MARKET_RESET_MARKET);
  },

  [constants.SESSION_ADD_MARKETS]: async ({ commit }, markets) => {
    commit(constants.SESSION_SET_PROPERTY, { markets });
  },

  [constants.SESSION_DRAWER]: ({ commit }, data) => {
    commit(constants.SESSION_DRAWER, data);
  },

  [constants.SESSION_SHOW_DIALOG_CONNECT]: ({ commit }, showDialogConnect) => {
    commit(constants.SESSION_SET_PROPERTY, { showDialogConnect });
  },

  [constants.SESSION_SET_APP_INSTALL_DIALOG]: ({ commit }, newAppInstallDialogStatus) => {
    commit(constants.SESSION_SET_PROPERTY, { showAppInstallDialog: newAppInstallDialogStatus });
  },

  [constants.SESSION_SHOW_DIALOG_QR_ADDRESS]: ({ commit }, showDialogQrAddress) => {
    commit(constants.SESSION_SET_PROPERTY, { showDialogQrAddress });
  },

  [constants.SESSION_SHOW_NEW_CONTENT_DIALOG]: ({ commit }, { newContentAvailable }) => {
    commit(constants.SESSION_SET_PROPERTY, { newContentAvailable });
  },
  [constants.SESSION_SHOW_PK_ALERT]: ({ commit }, data) => {
    commit(constants.SESSION_SET_PROPERTY, { showPkAlert:data});
  },

  [constants.SESSION_ROUTE_BALANCE]: ({ commit }, data) => {
    commit(constants.SESSION_SET_PROPERTY, { routeBalance: data });
  },

  [constants.SESSION_ROUTE_DEPOSITS]: ({ commit }, data) => {
    commit(constants.SESSION_SET_PROPERTY, { routeDeposits: data });
  },

  [constants.SESSION_ROUTE_BORROWS]: ({ commit }, data) => {
    commit(constants.SESSION_SET_PROPERTY, { routeBorrows: data });
  },

  [constants.SESSION_IS_MOBILE]: ({ commit }, data) => {
    commit(constants.SESSION_SET_PROPERTY, { isMobile: data });
  },

  [constants.SESSION_GET_USER_ID]: async ({ commit, dispatch }, walletAddress) => {
    const { findFirstUsers } = await getUserId(walletAddress);
    if (findFirstUsers) {
      commit(constants.SESSION_SET_PROPERTY, { userId: findFirstUsers.id });
      dispatch(constants.MARKET_LOAD_MARKETS_USER_INFO);
      const registeredEmail= findFirstUsers.user_settings?.email_address
      commit(constants.SESSION_SET_PROPERTY, { notificationsEmail: registeredEmail });
  
  

    } else {
      dispatch(constants.MARKET_UPDATE_RBTC_BALANCE_IN_WALLET);
    }
  },
};

const mutations = {
  // eslint-disable-next-line no-shadow
  [constants.SESSION_SET_PROPERTY]: (state, data) => {
    const [[property, value]] = Object.entries(data);
    state[property] = value;
  },

  // eslint-disable-next-line no-shadow
  [constants.SESSION_DRAWER]: (state, payload) => {
    state.drawer = payload;
  },

  // eslint-disable-next-line no-shadow
  [constants.SESSION_SET_WALLET]: (state, payload) => {
    state.wallet = payload;
  },


  [constants.SESSION_SET_W3A_INSTANCE]: (state, newWeb3AuthInstance) => {
    state.web3AuthInstance = newWeb3AuthInstance;
  },
};

const getters = {

  // eslint-disable-next-line no-shadow
  [constants.SESSION_GET_ACCOUNT]: (state) => state.account,

  // eslint-disable-next-line no-shadow
  [constants.SESSION_GET_CHAIN_ID]: (state) => state.chainId,

  // eslint-disable-next-line no-shadow
  [constants.SESSION_GET_NETWORK_NAME]: (state) => {
    switch (state.chainId) {
      case 30:
        return 'Mainnet';
      case 31:
        return 'Testnet';
      case 1337:
        return 'Local';
      default:
        return '';
    }
  },

  // eslint-disable-next-line no-shadow
  [constants.SESSION_GET_WALLET_ADDRESS]: (state) => state.walletAddress,

  // eslint-disable-next-line no-shadow
  [constants.SESSION_GET_TROPYKUS]: (state) => state.tropykus,

  // eslint-disable-next-line no-shadow
  [constants.SESSION_IS_CONNECTED]: (state) => !!state.walletAddress,

  // eslint-disable-next-line no-shadow
  [constants.SESSION_IS_SUPPORTED_NETWORK]:
  // eslint-disable-next-line no-shadow
    (state) => state.supportedChains.indexOf(state.chainId) !== 1,

};

export default {
  state,
  actions,
  mutations,
  getters,
};
