/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
import { ethers, FixedNumber } from 'ethers';
import store from '@/store';
import CRBTCAbi from '@/abis/CRBTC.json';
import CRBTCCompanionAbi from '@/abis/CRBTCCompanion.json';
import * as constants from '@/store/constants';
import mathematics from '@/mathematics';
import Market from './market';
import { addresses } from './constants';

export default class CRbtc extends Market {
  constructor(address = '', chainId = process.env.VUE_APP_DEFAULT_CHAIN_ID) {
    super(address, CRBTCAbi, chainId);
    this.internalUnderlyingAssetSymbol = constants.RBTC_SYMBOL;
    this.companionAddress = addresses[chainId].cRBTCCompanion;
    this.companion = new ethers
      .Contract(this.companionAddress, CRBTCCompanionAbi, Vue.web3);
  }

  async exchangeRateCurrent(withAccount = true) {
    let account = null;
    if (withAccount) {
      const { SESSION_GET_ACCOUNT } = store.getters;
      account = SESSION_GET_ACCOUNT || null;
    }
    return super.exchangeRateCurrent(account, await Market.isCSat(this.marketAddress));
  }

  async exchangeRateStored(withAccount = true) {
    let account = null;
    if (withAccount) {
      const { SESSION_GET_ACCOUNT } = store.getters;
      account = SESSION_GET_ACCOUNT || null;
    }
    return super.exchangeRateStored(account, await Market.isCSat(this.marketAddress));
  }

  async underlyingAssetSymbol() {
    return this.internalUnderlyingAssetSymbol;
  }

  // eslint-disable-next-line class-methods-use-this
  async balanceOfUnderlyingInWallet(account) {
    return mathematics.div(await account.getBalance(), 1e18);
  }

  async supply(account, amountIntended, wallet = '', walletName) {
    return super.supply(account, amountIntended, wallet, walletName, true);
  }

  async redeem(account, amountIntended, isCRbtc = true) {
    return super.redeem(account, amountIntended, isCRbtc);
  }

  async borrow(account, amountIntended, walletAddress, isCRbtc = true) {
    return super.borrow(account, amountIntended, walletAddress, isCRbtc);
  }

  // eslint-disable-next-line class-methods-use-this
  async underlyingAssetName() {
    return `${this.internalUnderlyingAssetSymbol} Bitcoin`;
  }

  async getSubsidyFound() {
    return super.getSubsidyFound(true);
  }

  async repay(account, amountIntended, walletAddress, connection = '', walletName) {
    return super.repay(account, amountIntended, walletAddress, connection, walletName, true);
  }

  async getMarketCap(account) {
    const [, totalBorrows, cSatPrice] = await this
      .companion.connect(account).callStatic.getTotalBorrowsInOtherMarkets();

    const kSatPrice = FixedNumber.from(cSatPrice.toString(), 'fixed80x18')
      .divUnsafe(FixedNumber.from(1e18.toString(), 'fixed80x18'));

    let treshold = await this.companion.callStatic.marketCapThresholdMantissa();
    treshold = FixedNumber.from(treshold.toString(), 'fixed80x18')
      .divUnsafe(FixedNumber.from(1e18.toString(), 'fixed80x18'));

    const limitInUSD = FixedNumber.from(totalBorrows.toString(), 'fixed80x18')
      .mulUnsafe(FixedNumber.from(treshold.toString(), 'fixed80x18'))
      .divUnsafe(FixedNumber.from(1e18.toString(), 'fixed80x18'));

    let kSatTotalSupply = await this.instance.callStatic.totalSupply();
    kSatTotalSupply = FixedNumber.from(kSatTotalSupply.toString(), 'fixed80x18')
      .divUnsafe(FixedNumber.from(1e18.toString(), 'fixed80x18'));

    let kSatExchangeRate = await this.instance.connect(account)
      .callStatic.exchangeRateCurrent();
    kSatExchangeRate = FixedNumber.from(kSatExchangeRate.toString(), 'fixed80x18')
      .divUnsafe(FixedNumber.from(1e18.toString(), 'fixed80x18'));

    const totalDeposit = kSatTotalSupply.mulUnsafe(kSatExchangeRate)
      .mulUnsafe(kSatPrice)
      .addUnsafe(FixedNumber.from('1', 'fixed80x18'));

    let maxDeposit = limitInUSD.subUnsafe(totalDeposit)
      .divUnsafe(kSatPrice);

    maxDeposit = Number(maxDeposit._value) < 0 ? 0 : Number(maxDeposit._value);

    return {
      totalDeposit: Number(totalDeposit._value),
      limit: Number(limitInUSD._value),
      maxDeposit,
    };
  }

  async currentBalanceOfCTokenInUnderlying(account) {
    let currentBalanceOfCTokenInUnderlying = await super
      .currentBalanceOfCTokenInUnderlying(account);
    if (await Market.isCSat(this.marketAddress)) {
      const { realAmount } = await super.tropykusInterestAccrued(account);
      currentBalanceOfCTokenInUnderlying = realAmount;
    }
    return currentBalanceOfCTokenInUnderlying;
  }
}
