<template>
  <div class="left-bar">
    <v-navigation-drawer class="primary-color pl-3 left-bar-content"
      v-model="drawer"
      absolute
      :temporary="temporary"
      :permanent="permanent"
      @input="updateDrawer"
      >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <img class="logo-tropykus"
                width="160"
                height="40"
                 src="@/assets/icons/tropykus-beta.png"
                 alt="tropykus-logo"
            />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list class="list-left-bar" dense nav
      >
        <v-list-item-group v-model="group">
          <v-list-item class="left-list-item pa-0 ml-1">
            <router-link :to="{name: constants.ROUTES.HOME.DASHBOARD}"
             class="d-flex align-center active">
              <HomeIcon class ="ml-4 mr-5"/>
              <div class="b2-secondary">{{ $t('menu.sidebar.home') }}</div>
            </router-link>
          </v-list-item>

          <v-list-item class="left-list-item pa-0 ml-1">
            <router-link :to="{name: constants.ROUTE_NAMES.BALANCE}"
                        class="d-flex align-center active">
                  <DollarIcon class ="ml-4 mr-5 mt-1"/>
              <div class="b2-secondary">{{ $t('menu.sidebar.balance') }}</div>
            </router-link>
          </v-list-item>

          <v-list-item class="left-list-item pa-0 ml-1"
         >
         <router-link :to="{name: constants.ROUTES.HOME.SECTION, params: {id: 'savings'} }"
                        class="d-flex align-center active">
            <PayIcon class="ml-4 mr-5"/>
              <div class="b2-secondary">{{ $t('menu.sidebar.deposit') }}</div>
          </router-link>
          </v-list-item>
          <v-list-item class="left-list-item pa-0 ml-1"
         >
         <router-link :to="{name: constants.ROUTES.HOME.SECTION, params: {id: 'loans'} }"
                        class="d-flex align-center active">
                        <BorrowIcon class="ml-4 mr-5"/>
              <div class="b2-secondary">{{ $t('menu.sidebar.borrow') }}</div>
          </router-link>
          </v-list-item>
          <v-list-item class="left-list-item pa-0 ml-1">
            <router-link :to="{name: constants.ROUTE_NAMES.EXCHANGES}"
              class="d-flex align-center active">
             <ExchangeIcon class="ml-4 mr-5"/>
              <div class="b2-secondary">{{ $t('menu.sidebar.exchanges') }}</div>
            </router-link>
          </v-list-item>

          <v-list-item class="left-list-item pa-0 ml-1">
            <router-link :to="{name: constants.ROUTE_NAMES.MARKETS}"
              class="d-flex align-center active">
             <MarketsIcon class="ml-4 mr-5"/>
             <div class="b2-secondary">{{ $t('menu.sidebar.markets') }}</div>
            </router-link>
          </v-list-item>

          <v-list-item class="left-list-item pa-0 ml-1">
            <a
              href="https://somos.tropykus.com/people/blog"
              target="_blank"
              class="d-flex align-center">
              <img class="ml-4 mr-5" src="@/assets/icons/blog.svg" alt="tutorial-icon"/>
              <div class="white--text b2-secondary">Blog</div>
            </a>
          </v-list-item>

          <v-list-item class="left-list-item pa-0 ml-1">
            <a
              href="https://tropykus-21211885.hubspotpagebuilder.com/tropykus-faqs#"
              target="_blank"
              class="d-flex align-center">
              <img class="ml-4 mr-5" src="@/assets/icons/info-white.svg" alt="info-icon"/>
              <div class="white--text b2-secondary">{{ $t('menu.sidebar.FAQs') }}</div>
            </a>
          </v-list-item>

          <v-list-item class="left-list-item pa-0 ml-1">
            <button block class="lang-btn d-flex justify-start align-center" @click="changeLanguage"
                 depressed>
              <img class="ml-4 mr-5" src="@/assets/icons/idiom.svg" alt="language-icon"/>
              <div class="white--text b2-secondary">{{ $t('menu.sidebar.language') }}</div>
            </button>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-divider class="divider"></v-divider>
      <div class="d-flex pl-6 price-btc">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/tropycofinance.appspot.com/o/markets%2FrbtcIcon.png?alt=media&token=e68a3bb9-e0f9-4090-aae6-bcc8ae588a83"
          alt="rbtc-icon"/>
        <v-row class="d-flex flex-column ma-0">
          <v-row class="ma-0">
            <v-col class="ma-0 pa-0" lg="12">
             <span class="ml-4 p3-USD-values white--text text-left">
                {{ marketPrice | formatPrice }}
              </span>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-col lg="12">
              <span class="ml-1 row p3-USD-values white--text text-left">
              {{ $t('menu.sidebar.price') }}
              </span>
            </v-col>
          </v-row>
        </v-row>
      </div>

      <div class="d-flex justify-space-between align-center contact-tropykus">
        <a target="_blank" href="https://t.me/tropykus">
          <v-img contain  width="35" height="43" src="@/assets/icons/telegram.svg"/>
        </a>
        <a target="_blank" href="https://twitter.com/tropykus">
          <v-img contain height="19" src="@/assets/icons/twitter.svg"/>
        </a>
        <a target="_blank" href="https://www.youtube.com/channel/UCUgOzJjQAsQS2sFJ0-A87eQ">
          <v-img contain height="43" src="@/assets/icons/youtube.svg"/>
        </a>
        <a target="_blank" href="https://www.instagram.com/tropykus/">
          <v-img contain height="20" src="@/assets/icons/instagram.svg"/>
        </a>
        <a target="_blank" href="https://github.com/Tropykus/protocol">
          <v-img contain height="43" src="@/assets/icons/github.svg"/>
        </a>
      </div>

      <div class="force-bottom">
        <span class="p1-descriptions white--text">
          ID: {{ id }}
        </span>
      </div>

    </v-navigation-drawer>

  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import * as constants from '@/store/constants';
import { findOneMarket } from '@/services/markets';
import { addresses } from '@/middleware/contracts/constants';
import HomeIcon from '@/components/icons/HomeIcon.vue';
import DollarIcon from '@/components/icons/DollarIcon.vue';
import PayIcon from '@/components/icons/PayIcon.vue';
import BorrowIcon from '@/components/icons/BorrowIcon.vue';
import ExchangeIcon from '@/components/icons/ExchangeIcon.vue';
import MarketsIcon from '@/components/icons/MarketsIcon.vue';

export default {
  name: 'LeftBar',
  components: {
    HomeIcon,
    DollarIcon,
    PayIcon,
    BorrowIcon,
    ExchangeIcon,
    MarketsIcon,
  },

  data() {
    return {
      constants,
      addresses,
      drawer: false,
      permanent: true,
      temporary: false,
      setGroupDrawer: null,
      showDrawer: true,
      marketPrice: 0,
      group: null,
    };
  },
  computed: {
    ...mapState({
      account: (state) => state.Session.account,
      drawerStore: (state) => state.Session.drawer,
      chainId: (state) => state.Session.chainId,
    }),
    id() {
      return process.env.VUE_APP_COMMIT_ID ?? '';
    },
    isLoansActive() {
      return this.$route.path === '/home/section/loans';
    },
    isSavingsActive() {
      return this.$route.path === '/home/section/savings';
    },
  },
  methods: {
    ...mapActions({
      setDrawer: constants.SESSION_DRAWER,
    }),
    updateDrawer(value) {
      this.setDrawer(value);
    },
    windowSize() {
      if (window.innerWidth <= 990) {
        this.drawer = false;
        this.setDrawer(false);
        this.permanent = false;
        this.temporary = true;
      } else {
        this.setDrawer(false);
        this.permanent = true;
        this.temporary = false;
      }
    },
    reportWindowSize() {
      window.addEventListener('resize', () => {
        if (window.innerWidth <= 990) {
          this.permanent = false;
          this.temporary = true;
        } else {
          this.permanent = true;
          this.temporary = false;
        }
      });
    },
    changeLanguage() {
      switch (this.$i18n.locale) {
        case 'es':
          this.$i18n.locale = 'en';
          break;
        case 'en':
          this.$i18n.locale = 'pt';
          break;
        default:
          this.$i18n.locale = 'es';
      }
    },
    async getPrice() {
      const { market } = await findOneMarket();
      this.marketPrice = market.underlying_token_price;
    },
    redirect(routePath, section) {
      const to = { name: routePath, params: { id: section } };
      this.$router.push(to);
    },
  },
  watch: {
    group() {
      this.setDrawer(false);
    },
    drawerStore() {
      this.drawer = this.drawerStore;
    },
    setGroupDrawer() {
      if (window.innerWidth <= 768) {
        this.setDrawer(false);
        this.showDrawer = false;
      } else {
        this.showDrawer = true;
        this.setDrawer(true);
      }
    },
  },
  created() {
    this.getPrice();
    this.windowSize();
    this.reportWindowSize();
  },
};
</script>
